body {
    margin: auto;
    font-family: -apple-system, BlinkMacSystemFont, sans-serif;
    overflow: auto;
    background: linear-gradient(315deg, rgb(22, 2, 21) 3%, rgb(8, 28, 48) 38%, rgb(22, 54, 52) 68%, rgb(61, 9, 9) 98%);
    animation: gradient 15s ease infinite;
    background-size: 400% 400%;
    background-attachment: fixed;
    height: 100%;
    /* margin-bottom: 10%; */
}

@media (min-width:551px) and (max-width: 1200px) {
  .title,.left,.right{
    font-size: 40px !important;
  }
  .home-page{
    padding-bottom: 4%!important;
  }
  .tabs{
    margin-top: 5%!important;
  }
  .arabic{
    font-size: 25px!important;
  }
  
}

@media(max-width:550px){
  .title,.left,.right {
    font-size: 35px !important;
    
    
  }
  .arabic{
    font-size: 16px!important;
    padding-top: 5%;
    

  }
  .home-page{
    padding-bottom: 5%!important;
    /* padding-top: 15% !important; */
  }
}
@media (min-width: 1500px) {
    body {
      padding-left: 0%;
      padding-right: 0%;
    }
    .home-page{
      padding-bottom: 5%!important;
    }
    
  }
  
@keyframes gradient {
    0% {
        background-position: 0% 0%;
    }
    50% {
        background-position: 100% 100%;
    }
    100% {
        background-position: 0% 0%;
    }
}

.wave {
    background: rgba(101, 97, 97, 0.25);
    border-radius: 1000% 1000% 0 0;
    position: fixed;
    width: 200%;
    height: 12em;
    animation: wave 10s -3s linear infinite;
    transform: translate3d(0, 0, 0);
    opacity: 0.8;
    bottom: 0;
    left: 0;
    z-index: -1;
}

.wave:nth-of-type(2) {
    bottom: -1.25em;
    animation: wave 18s linear reverse infinite;
    opacity: 0.8;
}

.wave:nth-of-type(3) {
    bottom: -2.5em;
    animation: wave 20s -1s reverse infinite;
    opacity: 0.9;
}

@keyframes wave {
    2% {
        transform: translateX(1);
    }

    25% {
        transform: translateX(-25%);
    }

    50% {
        transform: translateX(-50%);
    }

    75% {
        transform: translateX(-25%);
    }

    100% {
        transform: translateX(1);
    }
}

.home-images{
  display: flex;
  flex-direction: column;
}

/* .title{
  padding-top: 5%;
  margin-top: 5%;
    
} */

.left,
.right {
  flex-grow: 1;
  /* text-align: center; */
  font-weight: 700;
  font-size: 75px;
  font-family: system-ui,sans-serif;
  font-stretch: expanded;
  
  color: rgba(250, 253, 253, 0.726);
}

.left{
    text-align: right;
}
.right{
    text-align: left;
}
.moving-component-container {
    display: flex;
  justify-content: center;
  }

  .moving-component-wrapper {
    display: flex;
  align-items: center;
  }

  .arabic{
    text-align: center;
    font-size: 35px;
    color: #e5e5db;
    font-style: italic;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-overflow: hidden;
    margin-top: 3%;
    margin-bottom: 3%;
  }
  @media (max-width: 550px) {
    .search-input {
      margin-top: 10%;
    }
    .home-main{
      
      border-radius:7px;
    }

    .tabs{
      margin-top: 10%!important;
    }

    .first-tab{
      padding: 5% 3%!important;
      width: 25%!important;
      margin-left: 15%!important;
    }
    .second-tab{
      padding: 5% 3% !important;
      width: 25%!important;
      margin-right: 15%!important;
    }
  }

  .search-input input[type=text]{
    text-align: center;
    width: 80%;
    margin-top: 5%;
    margin-left: 22%;
    height: 50px;
    float:none;
    box-sizing: border-box;
    border-radius: 10px;
    border-color: rgb(196, 198, 199);
    
    
  }

  .search-input::placeholder {
    font-family:'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  }

  .search-input {
    display: flex;
    align-items: center;
    font-size: medium;
    flex-direction: row;
  }
  
  .search-icon {
    display: flex;
    align-items: center;
    /* padding: 5px; */
    cursor: pointer;
  }
  

  .tabs{
    display: flex;
    margin-top: 5%;
    flex-direction: row;
    justify-content: space-between;
    background-color: #1a050515!important;
  }

  .first-tab{
    text-align: center;
    background-color: rgba(250, 253, 253, 0.726);
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 22px;
    /* display: block; */
    background-repeat: no-repeat;
    background-size:contain;
    color: rgb(7, 5, 5);
    border-radius: 12px;
    width: 20%;
    height: 100%; 
    cursor: pointer;
    margin-left: 20%;
    margin-right: 5%;
    margin-top: 5%;
    margin-bottom: 5%;
    padding: 3% 3%;
    transition: all .2s ease-in-out;
    
  }
  

  .first-tab img{
    
    width: 100%;
    height: 200px;
    border-radius: 7px;
  }

  .first-tab p,.second-tab p{
    
    padding-top: 5px;
    
    margin-top: 10px;
    color: #1a0505;
  }

  .second-tab{
    text-align: center;
    background-color: rgba(250, 253, 253, 0.726);
    color: rgb(7, 5, 5);
    border-radius: 12px;
    width: 20%;
    height: 100%;
    margin-right: 20%;
    margin-top: 5%;
    cursor: pointer;
    margin-bottom: 5%;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 22px;
    transition: all .2s ease-in-out;
    padding: 3% 3%;

  }

  .second-tab img{
    
    width: 100%;
    height: 200px;
    border-radius: 7px;
  }
  .down{
    size: 10px;
  }

  
.first-tab:hover,
.second-tab:hover {
  border:solid 3px;
  border-color: #3eb097; /* Change border color on hover */
}



  



  .centered-button {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    background-color: rgba(213, 201, 201, 0.8); /* Adjust the background color and opacity as needed */
    padding: 10px;
    border-radius: 5px;
  }
  
  .centered-button p {
    margin: 0;
    font-size: 18px; /* Adjust the font size as needed */
    font-weight: bold;
  }
  
  .centered-button .down {
    font-size: 24px; /* Adjust the font size as needed */
  }


  @media (max-width: 550px) {
    .centered-button{
      height: 50%;
    }
    .centered-button .down {
      font-size: 10px; /* Adjust the font size as needed */
    }
    .centered-button p {
        font-size: 10px!important;
    }
    .slide{
      height:150px;
      margin-bottom: 10px!important;
      margin-top: 20px!important;
    }
  }



  /* Slideshow */

.slideshow {
  margin: 10px auto;
  overflow: hidden;
  max-width: 100%;
}

.slideshowSlider {
  white-space: nowrap;
  transition: ease 1000ms;
}

.slide {
  display: inline-block;
  
  height: 200px;
  width: 100%;
  border-radius: 20px;
}


/* Buttons */

.slideshowDots {
  text-align: center;
}

.slideshowDot {
  display: inline-block;
  height: 10px;
  width: 10px;
  border-radius: 50%;

  cursor: pointer;
  margin: 15px 7px 0px;

  background-color: #c4c4c4;
}

.slideshowDot.active {
  background-color: #6a0dad;
}
