.sub{
    background-color: rgb(224, 222, 218);
    display: flex;
    flex-direction: row;
    
  

}
.filter{
    width: max-content;
    background-color: rgba(92, 96, 109, 0.6);
    overflow-y: auto;
    padding: 2% 2%;
    border-radius: 5px;
}
.items{
    /* background-color: #e3e6e4!important; */
    
    padding-bottom: 5%;
    padding-left: 2%;
    padding-right: 2%;
    
}

@media (min-width: 1500px) {
  .container{
    width:100%!important;
    max-width: none!important;

  }
  .filter{
    width:25%!important;
    padding-top: 2%;
    padding-left: 2%;
    padding-right: 5%;
    
  }
  .items{
    overflow: auto;
  }


}

h2{
    color: #e9d7d7;
    font-size: 10px;
}

.main h3{
    background-color: rgba(92, 96, 109, 0.6) !important;
    padding-top: 4%;
    padding-left: 2%;
    padding-bottom: 3%;
    font-size: 30px;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;

    
}
@media (max-width: 550px) {
  /* .main{
    padding-top: 8%;
  } */
  .container,.items{
    padding-right: 0%!important;
    margin-right: 1%;
  }
  .category h6{
    font-size: small!important;
  }
  .category img{
    padding: 0 3%!important;
  }
  .main h3{
    /* background-color: #f7f8fa !important; */
    padding-top: 5%!important;
    padding-left: 5%!important;
    padding-bottom: 5%!important;
    font-size: 20px!important;

    
}
}

@media (min-width:551px) and (max-width: 1200px) {
  
  
}
.container {
    display: flex;
    
    height: 100vh;
    max-width:none!important;
    /* background-color: #e3e6e4!important; */
  }
  

  /* @media (min-width: 1500px) {
    .container {
      padding: 0 calc((100vw - 1500px) / 2); 
    }
  } */

  
  .scrollbar {
    scrollbar-width: thin;
    scrollbar-color: #888 #f5f5f5;
  }
  
  .scrollbar::-webkit-scrollbar {
    width: 3px;
  }
  
  .scrollbar::-webkit-scrollbar-track {
    background-color: #f5f5f5;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 4px;
  }
  
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: #555;
  }

  .card-img-top {
    height: 200px; /* Adjust the height value as needed */
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .products{
    margin-top: 3% !important;
    gap: 5%;
    margin-left: 2%;
    
  }

  .card:hover{
    box-shadow: 0 0 15px rgba(122, 120, 120, 0.938); /* Add a box shadow on hover */
  }

 /* Category Filter*/
 ul {
  list-style-type: none;
  overflow-y: auto;
  padding: 0;
}

li {
  margin-bottom: 10px;
}

label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
}

.MuiTypography-body1 {
  font-size: 16px;
  font-weight: bold;
}

.MuiTypography-body2 {
  font-size: 14px;
}
/* .MuiBox-root{
  background-color: #8d929a!important;
} */

.MuiCheckbox-root {
  color: #2196f3;
  
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: #798b76;
}

.MuiTypography-colorTextPrimary {
  color: rgb(29, 29, 31);
}

.product-items{
  padding:5px!important;

}

