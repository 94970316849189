.userdashboard{
    background-color: aquamarine;
}
.order-title h1{
    color: rgb(34, 37, 39);
}
.dashboard-container{
    display: flex;
    flex-direction: column;
    height: 80%;
    gap: 2%;
    overflow: auto;
}

.logout-button{
    width: 10%;
    margin-left: 80%;
}


.order-title {
    text-align: center;
    margin-bottom: 20px;
  }
  .mb-4 h3{
    margin-bottom: 3%;
  }
  
  .order-list {
    list-style-type: none;
    padding: 0;
  }
  
  .order-item {
    margin-bottom: 20px;
    border: 1px thin #474545;
    border-radius: 5px;
    padding: 10px;
    color: black;
    background-color: #ffffffba;
  }
  
  .order-details {
    margin-bottom: 10px;
  }
  
  .order-details h3 {
    margin-bottom: 5px;
  }
  
  .order-details p {
    margin-bottom: 5px;
  }

  .delivery-address{
    width: 50%;
  }