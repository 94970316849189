.logout-sidebar{
    margin-top: 20%;
    float: inline-start;
    margin-left: 10%;
    /* margin-right: 10%; */
}
a {
    color: #007bff;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }