.category img{
    size: cover;
    width: 70%;
    height: 60%;
    margin-top: 15%;
    padding-left: 15%;
    padding-right: 15%;
    border-radius: 50%;
    
}

.category{
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    word-wrap: break-word;
    font-size: small;
    font-weight: 400;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    color: rgb(34, 24, 24);
    padding-bottom: 4%;
    overflow: hidden;
    /* padding-left: 2%; */
}

.category:hover {
    transform: scale(1.05); /* Scale the item on hover */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3); /* Add a box shadow on hover */
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Add transitions for smooth animation */
  }

  .category img:hover{
    transform: scale(1.05); /* Zoom out the image on hover */
  transition: transform 0.3s ease; /* Add transition for smooth animation */
  }