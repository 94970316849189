@import url("https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap");

html,
body,
#root,
.app,
.content {
  height: 100%;
  width: 100%;
  font-family: "Source Sans Pro", sans-serif;
  
    background-color: rgba(22, 12, 12, 0.342) !important;
  
}

.app {
  display: flex;
  position: relative;
}

::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #e0e0e0;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.orders-container {
  overflow-x: auto; /* Enable horizontal scrolling */
  white-space: nowrap; /* Prevent wrapping of content */
  /* Other styles for the container */
}

.delivery-address-cell {
  white-space: nowrap; /* Prevent wrapping of content */
  overflow: hidden; /* Hide any overflowing content */
  text-overflow: ellipsis; /* Show ellipsis (...) for truncated content */
}
.modal-product{
  background-color: rgba(54, 32, 32, 0.884) !important;
}

.custom-actions-container {
  display: flex;
  max-width: none!important; /* Override the max-width set by MUI Data Grid */
  margin-right: 10px;
}