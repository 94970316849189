.fixed-header {
    /* Other styles for the fixed header */
    /* min-height: 20%!important; */
    width: 100%;
  }
  
  .header-content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #e4e2e1;
    
    border-radius: 5px;
    width: 100%;
    
    padding: 1% 5%;
    /* Add any additional styling as needed */
  }
 
  .header-logo{
    width: 30px;
    height: 30px;
    margin-left: 10px;
  }

  /* .cart-icon{
    margin-right: 10px;
    margin-left: 10px;
  }
  .user-icon{
    margin-right: 10px;
    margin-left: 5%;
  } */

  .header-item {
    display: inline-block;
    margin-right: 20px;
    font-size: 18px;
    cursor: pointer;
    /* color: rgb(60, 46, 30)!important; */
    padding: 5px 10px;
    border-radius: 5px;
    transition: background-color 0.3s;
  }

 
  .header-item:hover {
    background-color: #e6e2e2;
  }

  .header-item.active {
    background-color: #bb3c3c;
    font-weight: bold;
  }

  @media (max-width: 768px) {
    /* Adjust the font size for smaller screens */
    .header-item {
      font-size: 14px;
    }
  }

.header-icons{
  display: flex;
  flex-direction: row;
  align-items: center;
    justify-content: space-between;
    margin-right: 2%;
    /* margin-left: 2%; */
    gap: 5%;
    width: 10%;
}

.search-input input{
  height: 40px !important;
}

/* .search-input-container {
  display: flex;
  align-items: center;
  height: 48px; 
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px;
} */

.search-mobile-input {
  height: 40px; /* Adjust the height as needed */
  padding: 8px; /* Adjust padding as needed */
  font-size: 16px; /* Adjust font size as needed */
  border: 1px solid #ccc;
  border-radius: 4px;
  flex: 1;
  /* margin-right: 8px; */
}