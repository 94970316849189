.product-details {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 5%; */
    padding-top: 5%;
    background-color: rgba(103, 123, 123, 0.59);
    padding: 0 15%;
    gap: 20px;
  }
  
  .image-container {
    flex: 1;
    display: flex;
    flex-direction: row;
    /* margin-right: 20px; */
    margin-top: 5%;
    /* gap: 5%; */
  }
  @media (max-width: 500px) {
    .image-container {
      height: 100%;
    }
   } 
  
  @media (max-width: 500px) {
    .product-details {
      display: flex;
      flex-direction: column; 
    }
   } 
  
  .other-images {
    display: flex;
    /* justify-content: flex-start; */
    flex-direction: column;
    margin-bottom: 10px;
    /* border-width: 2px;
    border-style: solid; */
  }
  
  .thumbnail-image {
    width: 80%;
    height: 90%;
    object-fit: cover;
    margin-right: 10px;
    cursor: pointer;
    margin-bottom: 10%;
    display: flex;
    flex-direction: column;
    /* gap: 50%; */
    border-radius: 5px;
    border: 1px solid transparent;
  padding: 5px;
  transition: border-color 0.3s, padding 0.3s;
    

  }
  .thumbnail-image:hover {
    border-color: #aca8a8;
    padding: 10px;
  }
  
  .main-image-container {
    position: relative;
    overflow: hidden;
    flex: 3;
    margin-top: 2%;
    margin-right: 5%;
    height: 100%;
    /* width: 60%; */
  }
  @media (max-width: 500px) {
    .main-image-container {
      margin-top: 5%;
    }
   } 
  
  .main-image {
    width: 100%;
    
    /* max-height: 400px; */
    /* min-height: 100%; */
    object-fit: contain;
    border-radius: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: #777;
   
  }


  .next-arrows{
    display: flex;
    flex-direction: row;
    padding:0 10%;
    gap:10%
  }
  
  .thumbnail{
    flex: 1;
    
    /* width: 20%; */
  }
  
  .image-zoom {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    display: flex;
    gap: 10px;
  }
  .zoom-container {
    position: absolute;
    top: 0;
    left: 100%;
    transform: translateX(-100%);
    width: 200px; /* Adjust the width as desired */
    height: 200px; /* Adjust the height as desired */
    border: 1px solid #ccc; /* Add border or styling as desired */
    display: none;
  }
  
  .main-image-container:hover .zoom-container {
    display: block;
  }
  
  
  .zoomed-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .arrow-icon {
    font-size: 24px;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 5px;
  border-radius: 50%;
  cursor: pointer;
  }
  
  .product-info {
    flex: 1;
    margin-top: 5%;
    margin-bottom: 5%;
  }
  
  .ratings {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  
  .stars {
    display: flex;
    margin-right: 5px;
  }
  
  .bi-star-fill {
    color: #f7d82c;
    margin-right: 3px;
  }
  
  .rating-text {
    margin-left: 5px;
    font-size: 14px;
    color: #777;
  }
  
  .price {
    font-size: 18px;
    margin-bottom: 10px;
  }
  .price-product{
    gap: 7px;
    display: flex;
    flex-direction: row!important;
  }
  .text-muted {
    color: #777;
  }
  
  .details {
    margin-top: 20px;
  }
  
  .detail-item {
    display: flex;
    align-items: center;
    margin-bottom: 5%;

    
  }
  .detail-item p,li{
    margin-bottom: 0;
  }
  .detail-item ul{
    padding-left: 1px;
  }
  
  
  label {
    font-weight: bold;
    font-size: small;
    margin-right: 10px;
    display:inline list-item;
  }
  
  select {
    padding: 5px;
  }
  
  .size-chart-button {
    padding: 5px 10px;
    background-color: #eee;
    border: none;
    cursor: pointer;
  }
  
  .description {
    margin-bottom: 10px;
  }
  
  .specifications ul {
    list-style: none;
    padding-left: 0;
  }
  
  .specifications li {
    margin-bottom: 5px;
  }
  
  .features ul {
    list-style: disc;
    padding-left: 20px;
  }

  .quantity {
    display: flex;
    align-items: center;
    margin-top: 20px;
  }
  
  .quantity-btn {
    font-size: large!important;
    /* padding: 5px 10px; */
    border-radius: 5px;
    cursor: pointer;
    background-color: #777;
  }
  
  .quantity-value {
    margin: 0 10px;
    font-size: 18px;
  }
  
  .action-buttons {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    gap: 10%;
  }
  
  .buy-now-btn,
  .add-to-cart-btn {
    font-size: 16px;
    padding: 10px 20px;
    border-radius: 10px;
    cursor: pointer;
    border-width: 1px;
    background-color: #1262da;
    color: #f3f0f0;
  }
  
  .buy-now-btn:hover,.add-to-cart-btn:hover {
    background-color: #045002;
    color: #e6dada;
  }
  
  /* .add-to-cart-btn {
    background-color: #243528;
    color: #fff;
  } */

  .modal-dialog {
    position: absolute;
    top: 20px; /* Adjust the top value as needed to position the modal */
    right: 20px; /* Adjust the right value as needed to position the modal */
    width: 70%;
    height: 50%;
    max-width: 400px; /* Limit the maximum width of the modal */
  }
  .modal-header{
    height: 15%;
  }
  .modal-body{
    padding: 5px!important;
    
  }
  /* Customize the modal content */
  .modal-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 5px;
    gap:5%;
    height: auto;
    width: 100%;
    margin: 0 15%;
    margin-top: 5px;
    border-radius: 10px;
    background-color: #6d4b4bdc!important;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  }

  .modal-image {
    max-width: 70%; /* Set the maximum width of the image to 100% */
    height: auto; /* Maintain the aspect ratio */
    margin-top: 5%;
  }

  @media(max-width:550px){
    .modal-image{
      
      width: 50%;
      margin-top: 5%;
    }
    .modal-content{
      width: 100%!important;
    }
    .modal-content Button{
      margin-left: 25%;
    }
  }

  @media(min-width:551px) and (max-width:1250px){
    .modal{
      margin-bottom: 30%!important;
    }
    .modal-content{
      height: 50%;
    }
    .modal-image{
      
      width: 50%;
      
      margin-top: 5%;
    }
  }
  
  .item-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    
    /* margin-bottom: 20px; */
  }
  
  .item-info img {
    width: 70%;
    height: 50%;
    object-fit: cover;
    margin-right: 10px;
  }
  
  .item-name {
    font-size: 10px;
    font-weight: bold;
    margin: 0;
  }
  
  .go-to-cart-btn {
    background-color: #007bff;
    color: #fff;
    border: none;
    padding: 5px 5px;
    font-size: 15px;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .go-to-cart-btn:hover {
    background-color: #0056b3;
  }

  .modal-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
  
  /* Style the close button */
  .modal-header .close {
    font-size: 1.5rem;
    font-weight: bold;
    opacity: 0.7;
    cursor: pointer;
    margin-left: auto;
  }

  .products{
    margin-left: 2%!important;
  }