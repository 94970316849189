.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (min-width: 1500px) {
  body {
    padding: 0 5%; 
  }
}

/* For WebKit-based browsers */
body::-webkit-scrollbar {
  width: 10px;
}

body::-webkit-scrollbar-thumb {
  background-color: #888;
}

/* For Firefox */
body::-moz-scrollbar {
  width: 10px;
}

body::-moz-scrollbar-thumb {
  background-color: #888;
}

.app__container {
  width: 100%;
  min-height: 80vh;
}

html,body{

  height: 100%;

  
}

.handle-like {
  position: absolute;
  top: 0;
  right: 0;
  margin-right: 2px;
}

.card-title {
  position: relative;
  display: flex;
  flex-direction: row;
  padding-top: 1px;
  margin-right: 1px;
  justify-content: space-between;
  align-items: center;
}
.like{
  margin-right: 1px;
}

.card_rec {
  display: flex;
  flex-direction: row;
}